.app-loading-screen{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .inner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo{
    height: 4rem;
    margin-bottom: 2rem;
  }
}

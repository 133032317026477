.dashboard-screen{
  flex: 1;
  display: flex;
  flex-direction: column;

  .body-container{
    display: flex;
    flex-direction: row;
    flex: 1;

    .content-container{
      flex: 1;
      margin: 1rem;
    }
  }
}


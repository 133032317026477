.entity-files-screen{
  display: flex;
  flex-direction: column;
  height: 100%;

  .alert, .toolbar{
    margin-bottom: 0.5rem;
  }

  .toolbar-input{
    width: 12rem;
  }

  $input-border-width: 1px;
  $input-border-color: #e0e0e0;
  & .MuiOutlinedInput-root {
    background-color: white;
    & fieldset{
      border-width: $input-border-width !important;
      border-color: $input-border-color !important;
    }
    &:hover fieldset{
      border-width: $input-border-width !important;
    }
    &.Mui-focused fieldset{
      border-width: $input-border-width !important;
    }
  }
}
.niobi-drawer{

  $drawer-width: 280px;
  width: $drawer-width;
  & .MuiDrawer-paper{
    width: $drawer-width;
  }

  .inner-container{
    margin-top: 48px;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
  }
}
@import "features/auth/AppLoadingScreen";
@import "features/dashboard/DashboardScreen";
@import "features/dashboard/NiobiAppBar";
@import "features/dashboard/NiobiDrawer";
@import "features/entities/EntitiesScreen";
@import "features/entityfiles/EntityFilesScreen";
@import "features/entityfiles/upload/EntityFileUploadDialog";
@import "features/login/LoginScreen";

.app-container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #fafafa;
}

form{
  display: flex;
  flex-direction: column;

  .input{
    margin-bottom: 1rem;
  }

  .checkbox{
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }

  .action-button{

  }
}

.link-unstyled{
  color: inherit;
  text-decoration: none;
}
.login-screen{
  flex: 1;
  display: flex;
  justify-content: center;

  .inner-container {
    margin-top: auto;
    margin-bottom: auto;

    .form-container {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-align: center;

      .logo {
        height: 4rem;
        object-fit: contain;
        margin-bottom: 1rem;
      }

      .title {
        margin-bottom: 2rem;
      }

      .alert {
        margin-bottom: 1rem;
        text-align: left;
      }
    }

    .copyright-container{
      padding: 2rem;
      text-align: center;
    }
  }
}